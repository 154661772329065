import React from 'react'
import CardFeature from '../common/CardFeature'
import { FaEnvelope, FaHome } from 'react-icons/fa'
import { Link } from 'gatsby'

const PropertySection = ({ paginate, data }) => {
  const { currentPage, totalPages, totalItems } = paginate

  return (
    <div>
      <div className='mb-2 p-3 mb-2 bg-secondary'>
        <h6 className=' text-white mb-0'>
          Results {currentPage} - {totalPages} of {totalItems} properties
        </h6>
      </div>
      {data.length ? (
        <div className='row'>
          {data.map(item => (
            <div key={item.id} className='col-lg-4 col-md-6 col-12'>
              <CardFeature {...item} />
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className='my-2 p-3 bg-secondary'>
            <h6 className=' text-white mb-0'>
              Sorry, we could not find any results that match your request.
            </h6>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-12 p-2'>
              <div className='border p-3'>
                <div className='text-center'>
                  <FaEnvelope size='30' />
                  <h6>Get Email Alert </h6>
                </div>
                <p>
                  Subscribe to our email and we'll let you know when we have
                  property that match your request
                </p>
                <div>
                  <form className='widget widget-subscribe' method='GET'>
                    <div className='rld-single-input'>
                      <input type='text' name='name' placeholder='Full Name' />
                    </div>
                    <div className='rld-single-input'>
                      <input
                        type='text'
                        name='email'
                        placeholder='Your@email.com'
                      />
                    </div>
                    <button className='btn btn-yellow w-100'>Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-12 p-2'>
              <div className='border p-3'>
                <div className='text-center'>
                  <FaHome size='30' />
                  <h6>Send A Request </h6>
                </div>
                <p>
                  You can send us your request and an agent with your property
                  criteria will contact you.
                </p>
                <Link to='/contact'>
                  <button className='btn btn-yellow w-100'>Contact us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PropertySection
