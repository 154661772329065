import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { FaBath, FaVectorSquare, FaBed, FaUser, FaMapMarkerAlt } from 'react-icons/fa'
import urljoin from 'url-join'
import normalizeSlug from '../../utils/normalizeSlug'
import './card.css'
import { ProductJsonLd } from 'gatsby-plugin-next-seo'

const CardFeature = ({
  heroImage,
  name,
  agent,
  slug,
  propertyCategory,
  propertyType,
  location,
  locality,
  newerprice,
  olderprice,
  bedroom,
  toilet,
  area
}) => {
  let path = urljoin(
    'property',
    propertyCategory.slug,
    propertyType.slug,
    locality.city.slug,
    locality.slug,
    slug
  )
  path = normalizeSlug(path)

  const pricefix = `${newerprice.replace(/,/g, "")}`
  const company =`${agent.name}`

  return (
    
    <Link to={`/${path}`}>
      
<ProductJsonLd
      productName={name}
      images={[
        'https://example.com/photos/1x1/photo.jpg',
        'https://example.com/photos/4x3/photo.jpg',
        'https://example.com/photos/16x9/photo.jpg',
      ]}
      description='description'
      brand='ownahome.ng'
      offers={{
        price: `${pricefix }`,
        priceCurrency: 'NGN',
        priceValidUntil: '2020-11-05',
        itemCondition: 'New',
        availability: 'InStock',
        businessFunction : 'Sell',
        additionalType: 'http://schema.org/RealEstateListing',
        propertyType: `${propertyType.name}`,
        url: `/${path}`,
        seller: {
          name: {company},
        },
      }}
      mpn='925872'
    />

      <div className='single-feature  shadow-sm'>
        <div className='thumb' style={{ height: '180px' }}>
          <Img image={getImage(heroImage)} alt='card image' />
        </div>
        <div className='details'>
         
          <div  style={{ height: '100px' }}>
            {agent.name && (
              <h2 className='author text-muted'>
                <FaUser /> {agent.name}
              </h2>
              
            )}
            
            <h2 className=' h6 title readeal-top'>
              {name.length > 50 ? name.slice(0, 50) + '...' : name}
            </h2>
            <h2 className="text-muted h6">
              <FaMapMarkerAlt/> {location.length > 17 ? location.slice(0, 17) + '...' : location}
            </h2>
                 <div>
                 <h4 className='price h6'>₦{newerprice}</h4> 
            
            {olderprice && 
           <h4 className='price h6'><span className='mx-2'>-   </span>₦{olderprice}</h4> }
       
          </div> 
          </div>
          <ul className='info-list'>
            {bedroom && (
              <li className='mt-5'>
                <FaBed /> {bedroom}
              </li>
            )}
            {toilet && (
              <li className='mt-5'>
                <FaBath /> {toilet}
              </li>
            )}
            {area && (
              <li className='mt-5' >
                <FaVectorSquare /> {area}
              </li>
            )}
          </ul>
        
          <ul className='contact-list'>
            <li className='readeal-top'>
              <span className='btn btn-yellow'>View Details</span>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  )
}

CardFeature.propTypes = {
  heroImage: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  newerprice: PropTypes.string.isRequired,
  olderprice: PropTypes.string,
  area: PropTypes.string,
  agentName: PropTypes.string,
  slug: PropTypes.string.isRequired,
  bedroom: PropTypes.number,
  toilet: PropTypes.number,
  propertyCategory: PropTypes.object,
  locality: PropTypes.object
}

export default CardFeature
