import React, { useEffect, useState } from 'react'
import Filters from '../common/Filters'
import PropertySection from './PropertySection'
import { useGlobalContext } from '../../context/globalContext'
import { useLocation, navigate } from '@reach/router'
import Pagination from 'react-paginate'
import paginate from '../../utils/paginate'
import filterData from '../../data/filterData.json'

const PropertyListing = ({ properties }) => {
  const [curentPage, setCurrentPage] = useState(1)
  const { getProperty, newProp, setInitValue, initValue } = useGlobalContext()
  const location = useLocation()
  const paginatedResult = paginate(newProp.length, Number(curentPage))

  // consumed data on page mount
  useEffect(() => {
    getProperty(properties.nodes)
    // The pathname is to load data when there's changes and key also worked the same way, expect that it to keep a check on global state changes as well. **Use to load filters on state change**
  }, [location.pathname, location.key])

  // life cycle to update global state on path changes
  useEffect(() => {
    let category = ''
    let locality = ''
    let type = ''
    const categoryStartIndex = location.pathname.indexOf('/', 1)
    const categoryEndIndex = location.pathname.indexOf(
      '/',
      categoryStartIndex + 1
    )
    let categoryTypeIndex = location.pathname.indexOf('/', categoryEndIndex + 1)

    if (categoryEndIndex !== -1) {
      category = location.pathname.substring(
        categoryStartIndex + 1,
        categoryEndIndex
      )

      if (categoryTypeIndex !== -1) {
        // FF category types
        let subType = location.pathname.substring(
          categoryEndIndex + 1,
          categoryTypeIndex
        )

        let findType = filterData.types.find(type => type.slug === subType)
        if (findType !== -1) {
          type = subType
          locality = location.pathname.substring(categoryTypeIndex + 1)
        } else {
          locality = location.pathname.substring(categoryEndIndex + 1)
        }
      } else {
        let subType = location.pathname.substring(categoryEndIndex + 1)
        let findType = filterData.types.find(type => type.slug === subType)
        if (findType) {
          type = subType
        } else {
          locality = subType
        }
      }
    } else {
      category = location.pathname.substring(categoryStartIndex + 1)
    }

    let singleSelections = filterData.location.filter(
      loc => loc.slug === locality
    )

    setInitValue(prevState => ({
      ...prevState,
      type,
      category,
      location: locality,
      singleSelections
    }))
  }, [location.pathname])

  // Life cycle for queries on search change
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    let queryPage = query.get("page")
    if (queryPage) {
      setCurrentPage(queryPage)
    }
  }, [location.search])

  // paginated data
  const newData = newProp.slice(
    paginatedResult.startIndex,
    paginatedResult.endIndex + 1
  )

  // Handle pagination queries.
  const handlePageClick = data => {
    let selected = data.selected

    if (selected == 0) {
      navigate(`${location.pathname}`)
    } else {
      navigate(`${location.pathname}?page=${selected + 1}`)
    }
  }

  return (
    <div className='search-container'>
      <div className='row container-fluid  mx-auto'>
        <div className='col-xl-3 col-md-6 col-lg-4 sitebar'>
          <Filters />
        </div>
        <div className='col'>
          <PropertySection
            properties={properties}
            data={newData}
            paginate={paginatedResult}
          />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '30px' }}
          >
            {newData.length ? (
              <Pagination
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={paginatedResult.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                initialPage={paginatedResult.currentPage - 1}
              />
            ) : undefined}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyListing
