import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PropertyListing from '../components/PropertyListing'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const PropertyCategoryByCity = ({ data, pageContext }) => {
  const { propertyInfo, categoryInfo, properties } = data
  const title = `Property ${categoryInfo.name} in ${propertyInfo.name}`
  const description= ` Check out our listings of ${title}`
  const url = `/property/${categoryInfo.slug}/${propertyInfo.slug}`

  return (
    <Layout>
        <GatsbySeo
      title={title}
      description={description}
      openGraph={{
        title: {title},
        description: 'Description of open graph article',
        url: {url},
        images: 
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Og Image Alt Article Title A',
          },
          
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />

      
      <div className='pt-5 pd-bottom-70'>
        <div className='row mb-2 mt-4'>
          <div className='col-9 offset-md-3'>
            <h1 className=' text-center h4 my-4'>{title}</h1>
            <div className='d-flex justify-content-center align-items-center flex-wrap'>
              {propertyInfo.locality_in_city.map((node, index) => {
                return (
                  <h2 className='px-2 text-muted h6 border-end title readeal-top' key={index}>
                    <Link
                      to={`/property/${categoryInfo.slug}/${propertyInfo.slug}/${node.slug}`}
                    >
                      {node.name}
                    </Link>
                  </h2>
                )
              })}
            </div>
          </div>
        </div>
        <PropertyListing
          properties={properties}
          pageContext={pageContext}
          pathname={`/property/${categoryInfo.slug}/${propertyInfo.slug}`}
        />
      </div>
    </Layout>
  )
}

export default PropertyCategoryByCity

export const query = graphql`
  query allPropertyCityQuery($slug: String!, $category: String!) {
    propertyInfo: contentfulPropertyCategoryByCity(slug: { eq: $slug }) {
      id
      name
      slug
      description {
        description
      }
      locality_in_city {
        name
        slug
      }
    }

    categoryInfo: contentfulPropertyCategory(slug: { eq: $category }) {
      id
      slug
      name
    }

    properties: allContentfulProperty(
      filter: {
        propertyCategory: { slug: { eq: $category } }
        propertyCategoryByCity: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Property
      }
      ...PropertyPagination
    }
  }
`
